import React, { Component } from 'react';
import { QrReader } from 'react-qr-reader';
import { analyzeScan } from '@clients/shared/src/scan';
import { beep } from '../_utils';
import ErrorBoundary from '../_utils/ErrorBoundary';


class Scan2D extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 500,
      result: 'No result',
    }
    this.handleScan = this.handleScan.bind(this)
  }
  handleScan(result){
    if(result){
      console.log("Scanning:", result);
      beep();
      this.props.onScan(analyzeScan(result).bacId);
    }
  }
 handleError(err){
    console.error(err)
  }
  render(){
    const previewStyle = {
      height: 240,
      width: Math.min(320, window.innerWidth - 25),
    }
    return(
      <div>
        <ErrorBoundary>
        <QrReader
          className="scan"
          delay={this.state.delay}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}
          />
        </ErrorBoundary>
      </div>
    )
  }
}

export default Scan2D;
