import qs from 'query-string';
import { cleanObject } from '../main';

export default function makeUrl (pathname, search) {
  if (!(search)) {
    return pathname
  }
  if (typeof search==='string' && search.startsWith('?')) {
    return pathname + search;
  } else if (typeof search==='string') {
    return pathname + '?' + search;
  } else if (typeof search==='object') {
    return pathname + '?' + qs.stringify(cleanObject(search));
  }
};
