import { createSelector } from 'reselect';

//Current user selectors
export const getCurrentUser = store => store.currentUser.user;

//Events selectors
export const getEventsState = store => store.rootReducer.events;

export const getEventsList = store =>
  getEventsState(store) ? getEventsState(store).allIds : [];
 
export const getEventById = (store, id) =>
  getEventsState(store) ? getEventsState(store).byIds[id] : {};

export const getEventsById = (store) =>
  getEventsState(store) ? getEventsState(store).byIds : {};

export const getEvents = createSelector(
  [getEventsList, getEventsById], 
  (eventsList, eventsById) => eventsList.map(id => eventsById[id])
);

export const getBacsList = store =>
  getEventsList(store).map(id => getEventById(store, id)['bac'])

export const getLoadDate = store =>
  getEventsState(store) && getEventsState(store).loadDate;

export const getEventsSyncRequired = store =>
  getEventsState(store)?.syncRequired;

export const getEventsIsSyncing = store =>
  getEventsState(store)?.isSyncing;

//Places selectors
const getPlaces = store => store.rootReducer.places;

const getPlacesIdList = store =>
  getPlaces(store) ? getPlaces(store).allIds : [];
 
export const getPlace = (store, id) =>
  getPlaces(store) ? getPlaces(store).byIds[id] : undefined;

export const getPlacesById = (store) =>
  getPlaces(store) ? getPlaces(store).byIds : {};

export const getPlacesList = createSelector(
  [getPlacesIdList, getPlacesById], 
  (placesId, placesById) => placesId.map(id => placesById[id])
);

export const getPlacesLoadRequired = store =>
  getPlaces(store) ? getPlaces(store).loadRequired : undefined;

export const getPlacesIsLoading = store =>
  getPlaces(store) ? getPlaces(store).isLoading : undefined;

//ScanParams selectors
export const getDestination = store => store.scanParams.destination;

export const getBacEmpty = store => store.scanParams.bac_empty;

export const getBacClean = store => store.scanParams.bac_clean;

//Tours selectors
const getToursState = store => store.rootReducer.tours;

export const getTour = (store, id) => 
  getToursState(store) ? getToursState(store).byIds[id] : undefined;

const getToursIdList = store => 
  getToursState(store) ? getToursState(store).allIds : [];

const getToursById = store => 
  getToursState(store) ? getToursState(store).byIds : {};

export const getTours = createSelector(
  [getToursIdList, getToursById],
  (toursId, toursById) => toursId.map(id => toursById[id])
);
export const getToursLoadRequired = store =>
  getToursState(store) ? getToursState(store).loadRequired : undefined;

export const getToursIsLoading = store =>
  getToursState(store) ? getToursState(store).isLoading : undefined;


//Mixin of tours and places selectors
const getTourPlacesIdList = (store, id) =>
  getToursState(store) ? 
  getToursState(store).byIds[id].places.map(({id}) => id) : [];

export const getTourPlaces = createSelector(
  [getTourPlacesIdList, getPlacesById],
  (tourPlacesIds, placesById) => 
    tourPlacesIds.map(id => placesById[id] || {id})
);

//Utils selectors
export const getErrorMsg = store => store.rootReducer.errors.message;
