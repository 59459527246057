import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
import theme from './theme';
import '@clients/shared/src/utils/polyfills';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <CssBaseline>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </CssBaseline>
);
