import React, { useEffect, useState } from 'react';
import { Paper, Box, Table, TableHead, TableBody, TableCell,  TableRow,
  Typography } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import Load from '../_utils/Load';
import { Page, AddButton, Link, Tooltip } from '../main';


/** The list of all roles. */
export default function Roles() {
  const [roles, setRoles] = useState([]);
  const api = useApi();
  useEffect(() => {
    api.get()
      .then(setRoles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title='Rôles'
      options={<AddButton to='/roles/create'/>}
    >
    <Load isLoading={api.isLoading}>
      <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto' >
          <Table> 
            <TableHead> 
              <TableRow>
                <TableCell> Nom dans le code </TableCell>
                <TableCell> Nom affiché </TableCell>
              </TableRow>
            </TableHead> 
            <TableBody>
            {roles.map(({id, name, label, description}) => (
              <TableRow key={id}>
                <TableCell>
                  <Link to={'/roles/'+id}>{name}</Link>
                </TableCell>
                <TableCell>
                  <Tooltip text={description}>
                    <Typography>{label || '-'}</Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </Box>
    </Load>
    </Page>
  );
};
