import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Box } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useApi } from '@clients/shared/src/utils';
import { Card, SubmitButton } from '../main';
import BacInput from '../scan/BacInput';

/** The form to see bac shownId and edit it. */
export default function BacShowIdForm ({shown_id}) {
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const history = useHistory();
  const api = useApi();
  const onSubmit = (values) => {
    api.put('/bacs/' + shown_id + '/shownId', values)
    .then(() => setSucceeded(true))
    .then(() => history.replace('/bacs/' + values.shown_id));
  }
  return ( 
      <Card title='Nouveau numéro' mt={6}>
      <Formik onSubmit={onSubmit}
        initialValues={{shown_id}}
        enableReinitialize
      >
      {({setValues}) => (
        <Form>
          <Box width='200' mt={2}>
            <BacInput name='shown_id'
              required={false}
              reset={() => setValues({shown_id: ''})}
            />
          </Box>
          <SubmitButton {...{submitSucceeded}}>
            Modifier
          </SubmitButton>
        </Form>
      )}
      </Formik>
    </Card>
  );
};
