import React from 'react';
import { Settings } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { usePlaceOrState } from '@clients/shared/src/place/services';
import { Page, Link, Sub2 } from '../main';
import PlaceContent from './PlaceContent';

/** Use match to display place. */
export default function Place ({match}) {
  const [place] = usePlaceOrState(match.params.place_id, true);
  const { id, name } = place || {};
  return (
    <Page 
      title={name} 
      options={<EditLink placeId={id}/>}
    >
      <PlaceContent place={place}/>
    </Page>
  );
};

const EditLink = ({placeId}) => (
  <Link to={`/places/${placeId}/edit`}>
    <Tooltip title={<Sub2>Editer</Sub2>}>
      <Settings/>
    </Tooltip>
  </Link>);
