import React, { useState, Component } from 'react';
import * as Sentry from '@sentry/browser';
import { CanceledError } from 'axios';
import { useCurrentUser } from '@clients/shared/src/user/services';
import { Card, CardContent } from '@mui/material';
import FormPage from '../main/FormPage';

const { NODE_ENV, REACT_APP_RELEASE } = process.env;
if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://77dfd39aa51b4cc38647e91541e6ba5f@sentry.io/1418960",
    release: REACT_APP_RELEASE,
    ignoreErrors: [CanceledError.message],
  });
}

/** Class component to catch error. */
class ErrorBoundaryClassCpt extends Component {
  componentDidCatch (error, errorInfo) {
    this.props.componentDidCatch(error, errorInfo);;
  }
  render() {return (this.props.children)};
};

/** Catch and log error in rendering children. */
export default function ErrorBoundary ({ children }) {
  const [ hasError, setHasError ] = useState(false);
  const { email } = useCurrentUser();
  const componentDidCatch = (error, errorInfo) => {
    setHasError(true);
    console.log("Error boundary was called");
    console.log("Error:", error);
    console.log("Info:", errorInfo);
    Sentry.configureScope((scope) => {
      scope.setUser({'email': email});
      scope.setLevel('error');
    });
    Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
    });
  }
  return (hasError ? 
    <FormPage title="Nous avons un problème">
      <Card>
        <CardContent>
          <p style={{'color':'#e25d26'}}>
            L'application a rencontré un problème. <br />
            Essayez de recharger la page. <br />
            Envoyez un mail à contact@pandobac.com si l'erreur persiste.
          </p>
        </CardContent>
      </Card>
    </FormPage> : 
    <ErrorBoundaryClassCpt
      componentDidCatch={componentDidCatch}
      children={children}
    />);
};
