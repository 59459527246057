import { configureStore } from '@reduxjs/toolkit'; //redux-starter-kit rename in toolkit
import rootReducer from './reducers';

/** Redux store of reducers. */
const store = configureStore({
  reducer: { rootReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, //avoid warning in development
      serializableCheck: false,
    }),
})

export default store
