import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableBody, TableCell,  TableRow,
  } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { Page, Link, AddButton } from '../main';
import Load from '../_utils/Load';

/** The list of all bac models. */
export default function Models() {
  const [ models, setModels ] = useState([]);
  const api = useApi();
  useEffect(() => {api.get().then(setModels)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Page title='Modèles' options={<AddButton to='/models/create'/>}>
      <Load isLoading={api.isLoading}>
        <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
          <Table> 
            <TableHead> 
              <TableRow>
                <TableCell> Modèle </TableCell>
                <TableCell> Caractéristiques </TableCell>
              </TableRow>
            </TableHead> 
            <TableBody>{models.map(model => (
              <TableRow key={model.id}>
                <TableCell>
                  <Link to={'/models/' + model.id}>{model.name}</Link>
                </TableCell>
                <TableCell>
                  <Link to={'/models/' + model.id}>{getDetails(model)}</Link>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
          {(models.length===0) && <h3>Aucun modèle trouvé</h3>}
        </Box>
      </Load>
    </Page>);
};

const getDetails = ({ brand, color, length, width, height }) =>
  [brand, color, [length, width, height].join('x')].join(', ');

