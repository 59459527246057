import { useState, useEffect } from "react";
import { useApi, NONE_FILTER } from "@clients/shared/src/utils";
import { makeUrl } from "@clients/web/src/main";

//** Get models hook. */
export default function useGetModels ({after, before, workplace_id}) {
    const [models, setModels] = useState([]);
    const api = useApi({disableConcurrentRequest: true});
    useEffect(() => {
        api.get(makeUrl('/models', {after, before, workplace_id}))
            .then(models => [...models, {id: NONE_FILTER, name: 'sans modèle'}])
            .then(setModels);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [after, before, workplace_id]);
    return models
}
