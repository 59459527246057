import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { addDays, format, startOfToday } from 'date-fns';
import { useApi, stdDate } from '@clients/shared/src/utils';
import { usePlaces } from '@clients/shared/src/place/services';
import { useCurrentUser } from '@clients/shared/src/user/services';
import { FormPage, Card, SubmitButton, makeUrl } from '../main';
import PlacesStocksTable from './PlacesStocksTable';

/** A page to create and send situation email. */
export default function SituationMail() {
  const api = useApi();
  const places = usePlaces();
  const [placeId, setPlaceId] = useState('');
  const [mail, setMail] = useState({recipients:'', subject:'', content:''});
  const [stocks, setStocks] = useState([]);
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const currentUser = useCurrentUser();
  const selectPlace = ({placeId}) => {
    setPlaceId(placeId)
    api.get('/places/'+placeId+'/users')
      .then(users => makeRecipients(users))
      .then(recipients => setMail(mail => ({ ...mail, recipients})))
    setMail(mail => ({ ...mail, subject: makeSubject(placeId, places)}))
    api.get(makeUrl(
        '/reports/places', 
        {workplace_id: placeId, after: stdDate(after), before: stdDate(before)}
      ))
      .then(data => {
        setStocks(data)
        setMail(mail=> ({ ...mail, content: makeContent(data, currentUser)}))
      });
      setSucceeded(false);
    return null
  };
  const submit = values => {
    api.post(null, {...values, stocks})
      .then(() => {setMail(values);setSucceeded(true);});
  };
  return (
    <FormPage title='Mail situation semi automatique'>
      <Card title='Créer le mail'>
        <Formik
          onSubmit={selectPlace}
          initialValues={{placeId}}
          enableReinitialize
        >
          {() => (
          <Form>
            <Field 
              name='placeId' 
              label='Client' 
              component={TextField}
              type='text'
              select
              variant="standard"
              fullWidth
            >
              <MenuItem disabled value=''>Choisissez un lieu</MenuItem>
              {places.map(({id, name}) => 
              <MenuItem key={id} value={id}>{name}</MenuItem>)}
            </Field>
            <SubmitButton>Créer</SubmitButton>
          </Form>)}
        </Formik>
      </Card>
      <Card title='Editer et envoyer' mt={4}>
        <Formik onSubmit={submit} initialValues={mail} enableReinitialize>
          {() => (
          <Form>
            <Field 
              name='recipients'
              label='Destinataires'
              component={TextField}
              variant="standard"
              fullWidth
            />
            <Field
              name='subject'
              label='Sujet' 
              component={TextField}
              variant="standard"
              fullWidth
            />
            <Field 
              name='content' 
              label='Contenu' 
              component={TextField}
              variant="standard"
              multiline
              minRows={10}
              fullWidth
            />
            <SubmitButton {...{submitSucceeded}}>Envoyer</SubmitButton>
          </Form>)}
        </Formik>
      </Card>
      <Card mt={4}>
        <PlacesStocksTable stocks={stocks}/>
      </Card>
    </FormPage>);
};

const before = addDays(startOfToday(), -2);
const after = addDays(before, -31);

const makeRecipients = users => (users
    .filter(user => user.email && user.email!=='')
    .map(user => user.email).join(', '))

const makeSubject = (placeId, places) => {
  const name = places.find(p => p.id===placeId).name; 
  return name + ' | Situation des bacs au '+ format(before, 'dd/MM');
};

const makeContent = (places, user) => {
  const total = places.reduce((tot, place) => tot + place.older_cnt, 0);
  const stock = places.reduce((tot, place) => tot + place.older_stock, 0);
  const rate = Math.round((total-stock)*100/total);
  const content =  "Bonjour à tous,\n\n" +
    "Vous trouverez ci-dessous la synthèse des livraisons de bacs réutilisables effectuées entre le " + format(after, 'dd/MM') + " et le " + format(before, 'dd/MM') + ". " +
    "Le taux de retour sur cette période est de " + rate + "%.\n" +
    "Par ailleurs, pensez à bien scanner les bacs en réception et en expédition tous les jours.\n\n" +
    "Je vous souhaite une belle fin de journée !\n" +
    user.first
  return content
  }
