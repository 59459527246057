import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Checkbox } from 'formik-mui';
import qs from 'query-string';
import { Box, Grid, MenuItem, FormControl } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { FormPage, SubmitButton, DatePicker, Card } from '../main';

/** The form to edit a specific lease. */
export default function Lease ({ match, history, location }) {
  const leaseId = match.params.lease_id;
  const search = qs.parse(location.search);
  const empty = {client_id:'', model_id:'', bac_count:'', note:'',
    deleted:false};
  leaseId && (empty['end'] = null)
  const [ lease, setLease ] = useState({...empty, ...search});
  const [ clients, setClients ] = useState(
    () => search.client_id ? [{id: search.client_id, name:''}] : []);
  const [ models, setModels ] = useState(
    () => search.model_id ? [{id: search.model_id, name:''}] : []);
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const api = useApi();

  useEffect(() => {leaseId && api.get().then(resp => {
    clients.length===0 && setClients([{id: resp.client_id, name:''}]);
    models.length===0 && setModels([{id: resp.model_id, name:''}]);
    setLease(resp)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseId]);
  useEffect(() => {api.get('/places').then(setClients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {api.get('/models').then(setModels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let title = 'Création d\'un contrat';
  let formTitle = 'Nouveau contrat';
  if (leaseId) {
    title = 'Contrat ' + lease.client + ' (' + lease.model + ')';
    formTitle = 'Modification';
  };
  const submitNew = values => api.post('/leases', values)
    .then(() => history.replace('/leases'));
  const submitUpdate = values => api.put(undefined, values)
    .then(setLease)
    .then(() => setSucceeded(true), () => setSucceeded(false));
  const submit = leaseId ? submitUpdate : submitNew;

  return (
    <FormPage title={title}>
      <Card title={formTitle}>
        <Formik 
          onSubmit={submit} 
          initialValues={lease} 
          enableReinitialize
        >
          <Box mt={4}>
            <Grid container component={Form} direction='column'>
              <FormControl variant="standard">
                <Field
                  label="Client"
                  component={TextField}
                  name='client_id'
                  type='text'
                  validate={required}
                  variant="standard"
                  select
                >
                  <MenuItem disabled value=''>Choisissez un client</MenuItem>
                  {clients.map(({id, name}) => 
                  <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Field>
              </FormControl>
              <FormControl variant="standard">
                <Field
                  label="Modèle"
                  component={TextField}
                  name='model_id'
                  type='text'
                  validate={required}
                  variant="standard"
                  select
                >
                  <MenuItem disabled value=''>Choisissez un modèle</MenuItem>
                  {models.map(model => 
                  <MenuItem key={model.id} value={model.id}>
                    {model.name} ({details(model)})
                  </MenuItem> 
                  )}
                </Field>
              </FormControl>              
              <Field
                component={TextField}
                name='bac_count'
                label='Nombre de bacs'
                type='number'
                validate={required}
                variant="standard"
                required
              />
              <Field
                component={TextField}
                name='note'
                label='Remarque'
                type='text'
                variant="standard"
                multiline
                inputProps={{maxLength: 160}}
              />
              {leaseId &&
              <DatePicker
                name='end'
                label='Fin du contrat'
                clearValue={null}
                fullWidth
              />}
              <Grid item>
                <Field
                  component={Checkbox}
                  type='checkbox'
                  name='deleted'
                />
                <label>Effacé</label>
              </Grid>
              <SubmitButton {...{submitSucceeded}}/>
            </Grid>
          </Box>
        </Formik>
      </Card>
    </FormPage>);
};

const details = ({ brand, color, length, width, height }) =>
  [brand, color, [length, width, height].join('x')].join(', ');

const required = value => (value == null ? 'Champs requis' : undefined);
