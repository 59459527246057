import React from 'react';
import { isToday } from 'date-fns';
import { Chip } from '@mui/material';
import { stringifyDate, stringifyTime } from '@clients/shared/src/utils';
import { Sub1 } from '../main';
import { turnToValidOptions } from './Fields';

export const GenericChip = 
  ({name, label, searchValues, setSearchValues, hideChip, clear=''}) => (
    ( hideChip
      || searchValues[name] === clear
      || searchValues[name] === undefined
    )
      ? null
      : <Chip
          key={name}
          label={<Sub1>{label}</Sub1>}
          onDelete={(searchValues[name] !== clear) 
            ? (() => setSearchValues(search =>({...search, [name]: clear})))
            : null}
        />
  );

export const SelectChip = ({items, ...others}) => {
  const { name, searchValues } = others;
  const label = turnToValidOptions(items)
    .find(({value}) => value === searchValues[name])
    ?.label;
  return <GenericChip {...{...others, label}} />;
};

export const BooleanSelectChip = ({trueName, falseName, ...others}) => (
  <SelectChip
    items={[
      {value: '', label: 'Non défini'},
      {value: true, label: trueName},
      {value: false, label: falseName},
    ]}
    {...others}
  />
)

export const DateChip = ({label, ...otherProps}) => {
  const {searchValues, name, withTime} = otherProps;
  return (
    <GenericChip
      label={formatDate(searchValues, name, withTime)}
      {...otherProps}
    />
  )
}

const formatDate = (searchValues, name, withTime) => {
  const date = new Date(searchValues[name]) || null;
  const prefix = name==='after' ? 'aprés' : 'avant';
  if (isToday(date)) {
    return `${prefix} ${withTime ? stringifyTime(date) : 'aujourd\'hui'}`
  }
  return `${prefix} le ${stringifyDate(date)}`;
}
