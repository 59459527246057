import React from 'react';
import { addHours } from 'date-fns';
import { Typography, Grid, Avatar, List, ListItem } from '@mui/material';
import { useDeleteEvent } from '@clients/shared/src/event/services';
import { pluralize, useIsOnline } from '@clients/shared/src/utils';
import { H6, Link } from '../main';
import BacCard from './BacCard';


/** A count of last 12h events + 3 last ones as cards. */
export default function EventsGlance ({ events }) {
  const renderedEvents = events.slice(0, 3);
  const to = `/events/history?after=${addHours(new Date(), -12).toISOString()}`;
  return (
    <Grid container item direction='column' sx={styles.main} md>
      <Grid item container direction='column' sx={styles.title}>
        <Grid item container direction='column' alignItems='center'>
          <H6 align='center'>BACS SCANNÉS</H6>
          <Typography align='center'>depuis 12h</Typography>
          <Avatar component={Link} to={to} sx={styles.counter}>
            {events.length}
          </Avatar>
        </Grid>
      </Grid>
      {renderedEvents.length > 0 && <CardList {...{renderedEvents}}/>}
    </Grid>
  );
}

const CardList = ({renderedEvents}) => {
  const isOffline = !useIsOnline();
  const deleteEvent = useDeleteEvent();
  return(
    <Grid item container
      alignItems='center'
      justifyContent='space-between'
      direction='column'
    > 
      <Typography align='center'>
          {pluralize('dernier scan', renderedEvents.length)}
      </Typography>
      <List dense sx={{width:'100%'}}>
        {renderedEvents.map(({id, bac, closed, unsync}) => {
          return ( 
            <ListItem key={id}>
              <Grid item xs>
                <BacCard
                  bacId={bac}
                  deleteEvent={() => deleteEvent(id, unsync)}
                  {...{closed, unsync, isOffline}}
                />
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  )
}


const styles = {
  counter: {
    width:{xs: 28, md:40},
    height:{xs: 28, md:40},
    fontSize:{xs: 15, md:20},
    backgroundColor: 'secondary.main',
    my: {xs: 2, md: 3}
  },
  main: {
    minHeight: {xs: '50%', md: '100%'},
    mr: {md: 4},
    width: '100%',
    boxShadow: theme => ({
      xs: 'none',
      md: theme.components.shadows.defaultProps.inner
    }),
  },
  title:{
    width: '100%',
    fontWeight: 1000,
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    mt: {xs: 2, md: 5}
  },
}
