import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import JsBarcode from 'jsbarcode';
import { beep } from '../_utils';
import Universal from './Universal';


const decodedErrorSum = (result) => {
  const decodes = result.codeResult.decodedCodes;
  let countDecodedCodes=0, err=0, error=undefined;
  for (var i=0; i<decodes.length; i++) {
    error = decodes[i].error;
    if (error!==undefined) {
      countDecodedCodes++;
      err+=parseFloat(error);
    }
  };
  return (err/countDecodedCodes);
}

/** Test page to scan barcode and QRcode. */
class TestDoubleScan extends Component {
  constructor(props){
    super(props);
    this.state = {
      results1D: [],
    };
  }
  componentDidUpdate() {
    JsBarcode('.barcode').init();
  }

  handleScan1D = (result) => {
    console.log('1D');
    const code = result.codeResult.code;
    const error = decodedErrorSum(result);
    const { results1D, } = this.state;
    if (error<0.1) {
      const codeResults = results1D.filter(r=>r.codeResult.code===code);
      const count = codeResults.length;
      const errors = codeResults.map(r=>decodedErrorSum(r));
      const errorsFilt = errors.filter(e=>e<0.1);
      const countFilt = errorsFilt.length;
      if (count>10 || countFilt>2) {
        this.handleResult1D(code);
      }
    }
    this.setState(state => ({results1D: state.results1D.concat(result)}));
    console.log('scan n°',results1D.length, 'code', code, 'error', error);
  }
  
  handleResult1D = (result) => {
    beep();
    this.setState({
      message: 'Selection du client avec la référence ' + result.slice(0,6),
      place_ref: result,
      is_qrCode: false,
      results1D: [],
    });
  }

  handleScan2D = (result) => {
    if (result.length===7) {
      beep();
      this.setState({
        message: 'Ajout du bac n°' +result +' au client',
        bac_shown_id: result,
        is_qrCode: true,
      });
    } else {
      this.handleResult1D(result)
    }
  }

  render(){
    const { message, place_ref, bac_shown_id, is_qrCode } = this.state;
    const code = is_qrCode ? bac_shown_id : place_ref; 
    return(
      <div id='scan'>
        <h1>Test du double scan</h1>
        { message && <p style={{margin:'10px 0', color:'blue'}}>{message}</p> }
        { message && (is_qrCode ? <QRCode size={60} value={code} /> : 
            <svg className='barcode' jsbarcode-value={code}/>)}
        <Universal 
          handleResult={this.handleScan2D} 
          handleResult1D={this.handleScan1D}  />
      </div>
    )
  }
}

export default TestDoubleScan;
