import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { Box, Paper, Table, TableBody, TableRow, TableHead, IconButton, Card,
  TableCell, TableSortLabel, ListItemButton, ListItemIcon, ListItemText 
} from '@mui/material';
import { UnfoldMore, UnfoldLess, CloudDownload } from '@mui/icons-material';
import { Transition } from 'react-transition-group';
import { stdDate, useApi } from '@clients/shared/src/utils';
import useToursState from '@clients/shared/src/tour/toursState';
import { useWorkplace } from '@clients/shared/src/place/services';
import { Load } from '../_utils';
import { SearchBar, useGetModels, useSearch } from '../searchbar';
import { Page, Link, makeUrl } from '../main';

/** Page showing places' stock and return rate. */
export default function DestinationsAnalytics ({location}) {
  const api = useApi({disableConcurrentRequest: true});
  const [allPlaces, setAllPlaces] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('stock');
  const search = qs.parse(location.search,
    {parseNumbers: true, parseBooleans: true});
  const workplace = useWorkplace();
  const { tours } = useToursState(workplace.id);
  useEffect(() => {
    api.get()
      .then(setAllPlaces)
      .catch(() => setAllPlaces([]));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  const models = useGetModels(search);
  const filters = [
    {template: 'workplace'},
    {template: 'after', initialValue: stdDate(-33)},
    {template: 'before', initialValue: stdDate(-2)},
    {template: 'tours', items: tours}, 
    {template: 'models', items: models, filterFn: null},
    {type: 'switch', name: 'isLastExcluded', label: 'Sans dernière livraison',
      initialValue: false},
    {type: 'switch', name: 'caretaker_is_self', label: 'Mes locations',
      initialValue: false},
  ];
  let [searchBarProps, places] = useSearch(filters, allPlaces);

  places = stableSort(
    places.map(p => computePlaceData(p, search.isLastExcluded)), 
    getSorting(order, orderBy));
  const not_empty = places.filter(p => p.cnt);
  const empty = places.filter(p => !(p.cnt));
  const total = places.reduce((tot, place) => tot + place.cnt, 0);
  const stock = places.reduce((tot, place) => tot + place.stock, 0);
  const rate = Math.round((total-stock)*100/total);
  
  const histotyUrl = makeUrl('/events/history', 
    {...search, showDeliveries: true,});
  const exportUrl = makeUrl('/api/export/destinations-analytics/'+workplace.id,
    search);
    
  return (
    <Page title='Synthèse des clients'> 
      <SearchBar {...searchBarProps} />
      <Load isLoading={api.isLoading}>
        <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
          {places.length ? 
          <Table>
            <EnhancedTableHead {...{order, setOrder, orderBy, setOrderBy}}/>
            <TableBody>
            {not_empty.map(place => (
              <TableRow hover key={place.id}>
                <TableCell>
                  <Link to={histotyUrl + '&text=' + place.name}>
                    <strong>{place.name}</strong> 
                  </Link>
                </TableCell>
                <TableCell>{place.cnt}</TableCell>
                <TableCell sx={{ display: { xs: 'none', lg: 'block' } }} >
                  {place.returns_cnt}
                </TableCell>
                <TableCell>{place.stock}</TableCell>
                <TableCell>{place.rate} %</TableCell>
              </TableRow>))}
              <CollapsedRows places={empty} url={histotyUrl}/>
              {places.length>1 && 
              <TableRow hover>
                <TableCell><strong>Total</strong></TableCell>
                <TableCell><strong>{total}</strong></TableCell>
                <TableCell sx={{ display: { xs: 'none', lg: 'block' } }} >
                  <strong>{total - stock}</strong>
                </TableCell>
                <TableCell><strong>{stock}</strong></TableCell>
                <TableCell><strong>{rate + " %"}</strong></TableCell>
              </TableRow>}
            </TableBody>
          </Table> : 'Aucune livraison trouvée'}
        </Box>
        <Box component={Card} maxWidth={300} my={6} mx='auto' p={2}>
          <ListItemButton component={'a'} href={exportUrl} download>
            <ListItemIcon><CloudDownload/></ListItemIcon>
            <ListItemText>Exporter les données</ListItemText>
          </ListItemButton>
        </Box>
      </Load> 
    </Page>
  );
};

const EnhancedTableHead = ({order, setOrder, orderBy, setOrderBy}) => (
  <TableHead>
    <TableRow>
      {[
        { id:'name', label:'Nom' },
        { id:'cnt', label:'Livrés' },
        { id:'returns_cnt', label:'Rendus', mdDown: true},
        { id:'stock', label:'Non rendus' },
        { id:'rate', label:'Tx retour' },
      ].map(({id, label, mdDown}) => (
        <TableCell
          key={id}
          sx={mdDown && { display: { xs: 'none', lg: 'block' } }}
          sortDirection={orderBy===id ? order : false}
        >
          <TableSortLabel
            active={orderBy===id}
            direction={order}
            onClick={() => {
              setOrder(((orderBy===id && order==='desc') ? 'asc' : 'desc'));
              setOrderBy(id);}
            }
          >
            <strong>{label}</strong>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>);

const CollapsedRows = ({places, url}) => {
  const [open, setOpen] = useState(false);
  const duration = 250;
  const transition = ['opacity', 'line-height', 'padding']
    .map(prop => prop + ` ${duration}ms ease-in-out`).join(',');
  const transitionCellStyles = {
    exiting:  { opacity: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 },
    exited:  { opacity: 0, lineHeight: 0, paddingTop: 0, paddingBottom: 0 },
  };
  if (!places || places.length===0) {
    return null
  }
  return <>
    <TableRow onClick={() => setOpen(open => !open)}>
      <TableCell colSpan={4} align='center'>
        <IconButton size='small'>
          {open ? <UnfoldLess/> : <UnfoldMore/>}
        </IconButton>
        {open ? 'Voir moins' : `Voir plus (${places.length})`}
      </TableCell>
      <TableCell sx={{ display: { xs: 'none', lg: 'block' } }} />
    </TableRow>
    <Transition in={open} timeout={duration}>
      {state => {
        const style = {transition, ...transitionCellStyles[state]};
        const truncated = state==='entered' ? places : places.slice(0,4);
        return <>
          {truncated.map(({id, name}) => (
            <TableRow key={id}>
              <TableCell style={style}>
                <Link to={url + '&text=' + name}>
                  <strong>{name}</strong> 
                </Link>
              </TableCell>
              <TableCell style={style}>0</TableCell>
              <TableCell
                style={style}
                sx={{ display: { xs: 'none', lg: 'block' } }}
              >
                -
              </TableCell>
              <TableCell style={style}>-</TableCell>
              <TableCell style={style}>-</TableCell>
            </TableRow>
            ))}
        </>;
      }}
    </Transition>
  </>;
};

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => ((order === 'desc') ? 
  (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy));

const computePlaceData = (place, isLastExcluded) => {
  const cnt = isLastExcluded ? place.older_cnt : place.cnt;
  const stock = isLastExcluded ? place.older_stock : place.stock;
  return ({
    ...place,
    cnt,
    stock,
    returns_cnt: cnt - stock,
    rate: cnt ? Math.round(100*(1-stock/cnt)) : -1,
  })
}
