import React from 'react';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import Link from './Link';
import Tooltip from './Tooltip';

/* Plus (+) button used as a Page option.*/
export default function AddButton({to}) {
  return (
    <IconButton
      size='small'
      sx={{color: 'text.secondary'}}
    >
      <Link to={to}>
        <Tooltip text='Ajouter'>
          <Add />
        </Tooltip>
      </Link>
    </IconButton>
  );
}
