import React from 'react';
import { Box } from '@mui/material';
import { ItemAutocomplete, filterAndSort, highlightMatch } from '../_utils';

/** Autocomplete field fetching places' suggestions. */
export default function PlaceAutocomplete(props) {
  return (
    <ItemAutocomplete
      itemUrl='/places'
      name='place_id'
      filterOptions={(places, {inputValue}) =>
        filterAndSort(places, inputValue, ['name', 'address', 'city'])}
      renderOption={(optionProps, option, state) => (
        <PlaceItem 
          {...optionProps}
          key={option.id}
          place={option}
          query={state.inputValue}
        />
      )}
      renderLabel={place => place?.name || ''}
      {...props}
    />
  );
};

/** Small representation of a place. */
const PlaceItem = ({
  place: {name, address='', city, postal_code},
  query,
  ...itemProps
}) => (
  <li {...itemProps}>
    <Box p={0} m={1} width={1} color='text.primary' fontSize={13}>
      <Box color='text.secondary' fontSize={16}>
        {highlightMatch(name, query)}
      </Box>
        {address && (address.trim().length > 0) && (
          <>{highlightMatch(address, query)} <br/></>)}
        {highlightMatch(city, query)} - {postal_code}
    </Box>
  </li>
);
