const cleanReadRE = /^[^;?\n]+/ //keep text before special caracter
const pandohostRE = /pando\.ovh/i;
const pandobacRE = /pando\.ovh\/bacs\/([A-Z]\d{6})/i;
const graiRE = /8003\d{8}\d{5}\d{1}(\d{5,})/;

//TODO : when upgrading to Hermes v0.12, use "named capturing groups" :
// const pandohostRE = /(?<host>(www)?.pando.ovh)/i;
// const pandobacRE = /(?<host>(www)?.pando.ovh)\/bacs\/(?<bacId>[A-Z]\d{6})/i;
// const graiRE = /8003(?<owner>\d{8})(?<model>\d{5})(?<key>\d{1})(?<id>\d{5,})/

/* Analyzes a string to see if it contains valid barcode. */
export default function analyzeScan (text = '') {
  const clean = text.match(cleanReadRE)?.at(0) || '';
  const pandoURLScan = analyzePandoURL(clean);
  const graiScan = (!pandoURLScan.pathname && analyzeGRAI(clean)) || {};
  return {...pandoURLScan, ...graiScan, input: text};
}

/* Analyzes a string to see if it is a pando url. */
const analyzePandoURL = text => {
  const hostMatch = text.match(pandohostRE);
  const { index: hostIndex } = hostMatch || {};
  const host = hostMatch && hostMatch[0];
  const pathname = host && text.slice(hostIndex + host.length);
  const bacMatch = text.match(pandobacRE);
  const bacId = bacMatch && bacMatch[1];
  const error = !host ? `${text} doesn't contain pando.ovh url.` : null;
  return { pathname, bacId, error };
}

/* Analyzes a string to see if it is a valid GRAI. */
const analyzeGRAI = text => {
  const graiMatch = text.match(graiRE);
  const grai = graiMatch && graiMatch[1];
  return { bacId: grai, is_grai: (!!grai) };
}

//* TESTS *//*
// console.log(analyzeScan("qsjldqsdljk"))
// console.log(analyzeScan("https://pando.ovh/pla-%%ces/SFD"))
// console.log(analyzeScan("www.pando.ovh/bacs/A075816"))
// console.log(analyzeScan("8003087145481560070000113037")) //ok
// console.log(analyzeScan("8003087145481560070000113037A")) //ok
// console.log(analyzeScan("1348003087145481860040025787121")) //ok
// console.log(analyzeScan("2003087145481860040025787121")) //nok
// console.log(analyzeScan("80030871454X815600700113037")) //nok

