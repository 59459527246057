import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startOfYesterday } from 'date-fns';
import * as actions from '../redux/actions';
import { getEvents, getEventById, getLoadDate, getEventsSyncRequired, 
  getEventsIsSyncing } from '../redux/selectors';
import { useUpdatePlace } from '../place/services';
import { useApi, deviceStorage, useSetErrorMsg, Sentry,
  } from '../utils';
import { formatBacId } from '../scan';
import { eventServices } from './event-services';
import { useGetBacClean, useGetBacEmpty } from '../scan/services';
import { useIsLoggedIn } from '../user/services';

/** Get the list of events from store hook. */
export function useEvents () {
  return useSelector(state => getEvents(state));
};

/** Get an event from store hook. */
export function useEvent (eventId) {
  return useSelector(state => getEventById(state, eventId));
};

/** Get load events date from store hook. */
export function useLoadDate () {
  return useSelector(state => getLoadDate(state));
};

/** Load events in store hook. */
export function useLoadEvents () {
  const dispatch = useDispatch();
  const api = useApi();
  const unsyncEvents = useEvents().filter(({unsync}) => unsync);
  return [
    () => (
      deviceStorage.getItem('events').then(JSON.parse)
        .then(events => events && dispatch(actions.loadEvents(events)))
        .then(() => api.get('/events?after=' 
                            + startOfYesterday().toISOString()))
        .then(events => events.concat(unsyncEvents))
        .then(events => dispatch(actions.loadEvents(events)))),
    api.isLoading,
  ];
};

/** Initialize events in store hook */
export function useInitializeEvents() {
  const dispatch = useDispatch();
  return () =>  dispatch(actions.loadEvents([]));
}

/** Delete an event in store hook. */
export function useDeleteEvent () {
  const dispatch = useDispatch();
  const api = useApi();
  const updatePlace = useUpdatePlace();
  return (eventId, unsync) => unsync ? 
      dispatch(actions.deleteEvent(eventId)) :
      api.delete('/events/' + eventId)
        .then(event => {
          updatePlace(event.destination_id, 'stock', event.stock);
          dispatch(actions.deleteEvent(eventId));
        });
};

/** Replace an event in store hook. */
function useReplaceEvent () {
  const dispatch = useDispatch();
  return (eventId, newEvent) => 
    dispatch(actions.replaceEvent(eventId, newEvent));
};

/** Replace an event in store hook. */
function useUpdateEvent () {
  const dispatch = useDispatch();
  return (eventId, key, value) => 
    dispatch(actions.updateEvent(eventId, key, value));
};

/** Add an event in store hook. */
export const useAddEvent = () => {
  const dispatch = useDispatch();
  const events = useEvents();
  const updateEvent = useUpdateEvent();
  const setMsg = useSetErrorMsg();
  const bacEmpty = useGetBacEmpty();
  const bacClean = useGetBacClean();
  return (bacId, options, callback) => {
    const { destination_id, bac_updates } = options
    const is_empty = destination_id && bacEmpty;
    const is_clean = destination_id && bacClean;
    const formatError = formatBacId(bacId);
    if (formatError) {
      return setMsg(formatError + `, reçu: ${bacId}`);
    }
    const created = new Date().toISOString();
    if (bac_updates){
      const event = getSameBacUpdatesEvent(bacId, events);
      if (event) {
        const {id, bac_updates: previousUpdates} = event;
        return updateEvent(id,'bac_updates',{...previousUpdates,...bac_updates})
      };
    } else if (same12hEvent(bacId, destination_id, is_clean, is_empty, events)){
      return setMsg(bacId + ' est déjà scanné.')
    }
    setMsg();
    console.log('Creating event for bac', bacId, 'at', destination_id);
    const event = {
      is_empty,
      is_clean,
      ...options, 
      created,
      bac_shown_id: bacId,
      bac: bacId,
      id: 'temp_' + bacId + '_' + created,
      unsync: true,
    };
    const lastEvent = events.find(e => e.bac===bacId && !(e.closed));
    lastEvent && updateEvent(lastEvent.id, 'closed', true);
    dispatch(actions.addEvent(event));
  };
};

const getSameBacUpdatesEvent = (bacId, events) => 
  events.filter(e =>
    e.bac===bacId
    && e.bac_updates
    && e.unsync
  ).at(0)

const same12hEvent = (bacId, destination_id, is_clean, is_empty, events) => {
  const bacEvents = events.filter(e => e.bac===bacId)
  const similarEvents = eventServices.filter(bacEvents, {
    notReturned: true,
    placeId: destination_id,
    is_clean,
    is_empty
  })
  return eventServices.last12hEvents(similarEvents).at(0)
}

/** Load events from API and push unsync events to API in store hook. */
export function useSyncEvents () {
  const [loadEvents, isLoading] = useLoadEvents();
  const setRequireEventsSync = useSetRequireEventsSync();
  const isSyncing = useEventsIsSyncing();
  return [
    () => loadEvents().then(() => setRequireEventsSync(true)),
    isLoading || isSyncing
  ];
}

  /** Sync an event in store hook. */
  export function useSyncEvent () {
  const replaceEvent = useReplaceEvent();
  const updateEvent = useUpdateEvent();
  const updatePlace = useUpdatePlace();
  const handleDuplicateEvent = useHandleDuplicateEvent();
  const dispatch = useDispatch();
  const api = useApi();
  return event => {
    return (event.bac_updates
      ? handleSyncBacUpdates(event, api, dispatch, updateEvent)
      : handleSyncEvent(event, api, replaceEvent, handleDuplicateEvent,
          updatePlace)
    )
  };
};

const handleSyncBacUpdates = (event, api, dispatch, updateEvent) => {
  let shouldResetIdle = true;
  return api.put(`/bacs/${event.bac_shown_id}`, event.bac_updates)
    .then(() => {
      updateEvent(event.id, 'unsync', false);
    })
    .catch(error => {
      if (error.cause?.status === 0){ // Network error
        shouldResetIdle = false;
      } else {
        dispatch(actions.deleteEvent(event.id));
      }
    })
    .then(() => shouldResetIdle)
}

const handleSyncEvent = (event, api, replaceEvent, handleDuplicateEvent,
    updatePlace) => {
  let shouldResetIdle = true;
  const device_current_time =
      Date.now() - Date.parse(event.created) > 10*1000
      ? new Date().toISOString()
      : undefined;
  return api.post('/events', {...event, device_current_time})
    .then(syncEvent => {
      replaceEvent(event.id, syncEvent);
      syncEvent.destination_id && updatePlace(
        syncEvent.destination_id, 'stock', syncEvent.destination_stock
      );
    })
    .catch(error => {
      if (error.cause?.status === 0){ // Network error
        shouldResetIdle = false;
      } else if (error.cause?.status === 409) { // Duplicate event
        handleDuplicateEvent(event, error.event_id)
      } else {
        console.log(error);
        Sentry.captureException(error);
      }
    })
    .then(() => shouldResetIdle)
}

const useHandleDuplicateEvent = () => {
  const events = useEvents();
  const dispatch = useDispatch();
  const replaceEvent = useReplaceEvent();
  return (event, event_id) => {
    if (events.some(({id}) => id === event_id)) {
      dispatch(actions.deleteEvent(event.id));
    } else {
      replaceEvent(event.id, {...event, id: event_id, 'unsync': false});
    }
  }
}

/** Initialize, load and refresh events hook. */
export function useAutoupdateEvents () {
  const isLoggedIn = useIsLoggedIn();
  const [loadEvents, isLoading] = useLoadEvents();
  const initializeEvents = useInitializeEvents();
  const [isFresh, setIsFresh] = useState(false);
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      setIsFresh(false);
    }, 3*60*60*1000); // 3 hours
    return () => clearInterval(refreshInterval);
  }, []);
  useEffect(() => {
    if(!isLoggedIn){
      initializeEvents();
      setIsFresh(false);
    } else if(!isFresh){
      loadEvents();
      setIsFresh(true);
    }
  }, [isLoggedIn, isFresh])
}

/** Set if sync of events is required from store hook. */
export function useSetRequireEventsSync() {
  const dispatch = useDispatch();
  return isRequired => dispatch(actions.setEventsSyncRequired(isRequired));
}

/** Set if sync of events is pending from store hook. */
export function useSetEventsIsSyncing() {
  const dispatch = useDispatch();
  return isSyncing => dispatch(actions.setEventsIsSyncing(isSyncing));
}

/** Get if sync of events is required from store hook. */
export function useEventsSyncRequired() {
  return useSelector(state => getEventsSyncRequired(state))
}

/** Get if sync of events is pending from store hook. */
export function useEventsIsSyncing() {
  return useSelector(state => getEventsIsSyncing(state));
}
