import { useState, useEffect } from 'react';
import { useApi } from '../utils';
import { useTours, useUpdateTour } from './services';

/** State logic for Tours. */
export default function useToursState(workplaceId) {
  const api = useApi();
  const storeTours = useTours();
  const updateStoreTour = useUpdateTour();
  const [stateTours, setStateTours] = useState([]);
  const tours = workplaceId ? stateTours : storeTours;
  const updateTour = workplaceId ? ((tour) => setTours(tours => {
    const i = tours.findIndex(t => t.id === tour.id);
    tours[i] = tour;
    return tours.sort((a, b) => a.name.localeCompare(b.name));
  })) : updateStoreTour;

  useEffect(() => {
    workplaceId &&
      api.get('/tours?workplace_id=' + workplaceId).then(setStateTours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplaceId]);

  return {tours, updateTour};
};
