import React, { Component } from 'react';
import Quagga from 'quagga';
import './scan.css';

/** Parametres of the Scanner1D component. */
const config = {
  inputStream: {
    name: 'Live',
    type: 'LiveStream',
    constraints: {
      width: { ideal: 4096 },
      height: { ideal: 2160 },
      facingMode: 'environment' // or user
    }
  },
  numOfWorkers: 0,
  locator: {
    patchSize: 'small',
    halfSample: true,
    debug: {
      showCanvas: true,
      showPatches: true,
    }
  },
  decoder: {
    readers : [
      'code_128_reader',
      'ean_reader',
    ],
  },
}


/** Scanner1D starts a vido stream and decodes barcodes. */
class Scan1D extends Component {
  componentDidMount() {
    Quagga.init(config, (error) => {
      if (error) {
        console.log(error);
      } else {
      Quagga.start();
    }});
    
    //Draw rectangles to show what is happening
    Quagga.onProcessed(function (result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;
      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, 
            parseInt(drawingCanvas.getAttribute("width")), 
            parseInt(drawingCanvas.getAttribute("height")));
          result.boxes.filter(box => box !== result.box)
            .forEach(box => {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx,
                { color: "green", lineWidth: 2 });
          });
        }
        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx,
            { color: "#00F", lineWidth: 2 });
        }
        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, 
            drawingCtx, { color: 'red', lineWidth: 3 });
        }
      }
    });

    Quagga.onDetected(result => this.props.onScan(result));
  }

  componentWillUnmount() {
    Quagga.stop();
  }

  render() {
    return (
      <div id='interactive' className='viewport'/>
    );
  }
}

export default Scan1D;
