import React, { useEffect } from 'react';
import { useCurrentUser } from '@clients/shared/src/user/services';
import { usePlaceOrState } from '@clients/shared/src/place/services';
import { useSetBacClean, useSetBacEmpty
  } from '@clients/shared/src/scan/services';
import Page from '../main/Page';
import PlaceContent from '../place/PlaceContent';

/** Use current user workplace to display place. */
export default function Returns ({location}) {
  const setBacEmpty = useSetBacEmpty();
  const setBacClean = useSetBacClean();
  useEffect(() => {
    setBacEmpty(location.state?.bacEmpty);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.bacEmpty]);
  useEffect(() => {
    setBacClean(location.state?.bacClean);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.bacClean]);
  const { workplace_id } = useCurrentUser();
  const [ place ] = usePlaceOrState(workplace_id, true);
  return (
    <Page title='Réception'>
      <PlaceContent place={place}/>
    </Page>);
};
