import React, { useMemo } from 'react';
import { useUserIs } from '../user/services';
import { stdDate } from './dates';


const menuLinks = [
  {label: 'Envois pleins', shortLabel: 'Envois', roles:['sender_full'],
    bacEmpty: false,
    bacClean: null,
    web: { uri: '/places' },
    mobile: { navigator: 'PlaceNavigator', screen: 'Places' }
  },
  {label: 'Envois vides', shortLabel: 'Envois', roles: ['sender_empty'],
    bacEmpty: true,
    bacClean: null,
    web: { uri: '/places' },
    mobile: { navigator: 'PlaceNavigator', screen: 'Places' }
  },
  {label: 'Réception pleins', shortLabel: 'Réception',
    roles: ['receiver_full'],
    bacEmpty: false,
    bacClean: null,
    web: { uri: '/returns' },
    mobile: { navigator: 'ReceptionNavigator', screen: 'Returns' }
  },
  {label: 'Réception vides', shortLabel: 'Réception',
    roles: ['receiver_empty'], 
    bacEmpty: true,
    bacClean: null,
    web: { uri: '/returns' },
    mobile: {navigator: 'ReceptionNavigator', screen: 'Returns'}
  },
  {label: 'Lavé', shortLabel: 'Lavé', roles: ['washer'], 
    bacEmpty: true,
    bacClean: true,
    web: { uri: '/returns' },
    mobile: {navigator: 'ReceptionNavigator', screen: 'Returns'}
  },
  {label: 'Réception sales', shortLabel: 'Réception',
    roles: ['receiver_dirty'], 
    bacEmpty: true,
    bacClean: false,
    web: { uri: '/returns' },
    mobile: {navigator: 'ReceptionNavigator', screen: 'Returns'}
  },
  {label: 'Historique', roles: ['user'],
    web: { uri: '/events/history', search: '?after=' + stdDate(-7) },
    mobile: { navigator: 'HistoryNavigator', screen: 'History' }
  },
  {label: 'Suivi livraisons', roles: ['courier_admin'],
    web: { uri: '/events/history', 
      search: '?after=' + stdDate(-7)+'&include=next_event&showDeliveries=true'}
  },
  {label: 'Gestion', roles: ['leaser'],
    web: { uri: '/leasing' },
    mobile: { navigator: 'LeasingNavigator' }
  },
  {label: 'Utilisateurs', roles: ['admin'],
    web: { uri: '/users' }
  },
  {label: 'Synthèse', roles: ['admin'],
    web: { uri: '/reports/bacs' }
  },
  {label: 'Superadmin', roles: ['superadmin'],
    web: { uri: '/superadmin' }
  },
  {label: 'Aide',
    web: { uri: '/help' }
  },
  {label: 'Livraison', roles: ['courier'],
    mobile: { navigator: 'DeliveryNavigator' }
  }
];

const getLabel = (links, {label, shortLabel}) => {
  if (!shortLabel){
    return label;
  }
  const isShortLabelUnique =
    links.filter(link => link.shortLabel === shortLabel).length === 1;
  return isShortLabelUnique ? shortLabel : label;
}

const useDisplayedLinks = (client) => {
  const userIs = useUserIs();
  const displayedLinks = useMemo(
    () => menuLinks
      .filter(({roles}) => (!roles || roles.some(role => userIs(role))))
      .filter(link => link[client])
      .map((link, i, links) => ({
        ...link,
        label: getLabel(links, link)
      }))
  , [userIs, client]);
  return displayedLinks;
}

export const useWebLinks = () => {
  const displayedLinks = useDisplayedLinks('web');
  const webLinks = useMemo(
    () => displayedLinks.map(({ label, bacEmpty, bacClean, web }) => ({
      label,
      to: { 
        pathname: web.uri,
        search: web.search || '', 
        state: { bacEmpty, bacClean }
      }
    }))
  , [displayedLinks]);
  return webLinks;
}

export const useMobileLinks = () => {
  const displayedLinks = useDisplayedLinks('mobile');
  const mobileLinks = useMemo(
    () => displayedLinks.map(({ label, bacEmpty, bacClean, mobile }) => ({
      label,
      navigator: mobile.navigator,
      screen: {
        screen: mobile.screen,
        params: { bacEmpty, bacClean }
      }
    }))
  , [displayedLinks]);
  return mobileLinks;
}
