import React, { useState, useEffect } from 'react';
import { Paper, Box, TableContainer, Table, TableHead, TableBody, TableCell,
  TableRow, IconButton, Collapse, } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, Search} from '@mui/icons-material';
import { isFuture, isPast } from 'date-fns';
import { useApi, pluralize } from '@clients/shared/src/utils';
import { Page, Link, AddButton, makeUrl } from '../main';
import { Load }  from '../_utils';


/** The list of all leases by client then by model. */
export default function Leases() {
  const [ leases, setLeases ] = useState([]);
  const { get, isLoading } = useApi();
  useEffect(() => {
    get('/leases?active=True').then(setLeases);
  }, [get]);
  const clients = [...(new Set(leases.map(l => l.client)))];

  return (
    <Page
      title='Contrats de location'
      options={<AddButton to='/leases/create'/>}
    >
    <Load isLoading={isLoading}>
      <Box component={Paper}
        width={{sm:'90%'}} my={{sm:4}} mx='auto' maxWidth={600}
      >
        <TableContainer>
          <Table> 
            <colgroup>
              <col width='50%'/>
              <col width='30%'/>
              <col width='20%'/>
            </colgroup>
            <TableHead> 
              <TableRow>
                <TableCell> Client </TableCell>
                <TableCell> Total </TableCell>
                <TableCell align='right'> Détails </TableCell>
              </TableRow>
            </TableHead> 
            <TableBody>
            {clients.sort().map(c => (
              <PlaceRow key={c} leases={leases.filter(l => l.client===c)} />
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(leases.length===0) && <h3>Aucun contrat trouvé</h3>}
      </Box>
    </Load>
    </Page>);
};

const PlaceRow = ({ leases }) => {
  const [open, setOpen] = useState(false);
  const models = [...(new Set(leases.map(l => l.model)))];
  const { client, client_id } = leases.length ? leases[0] : {};
  return (
    <>
    <TableRow>
      <TableCell>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
        {client}
      </TableCell>
      <TableCell>{pluralize('bac', sumBacCnt(leases))}</TableCell>
      <TableCell align='right'>
        <Link to={`/places/${client_id}/get-bacs-analysis`}>
          <IconButton size="small">
            <Search />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{borderBottom: 'unset', padding: 0}} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table>
            <colgroup>
              <col width='3%'/>
              <col width='47%'/>
              <col width='30%'/>
              <col width='20%'/>
            </colgroup>
            <TableBody>
              {models.map(m => 
                <ModelRow key={m} leases={leases.filter(l => l.model===m)}/>
              )}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
    </>);
};

const ModelRow = ({leases}) => {
  const [open, setOpen] = useState(false);
  const { client_id, model, model_id } = leases.length ? leases[0] : {};
  return (
    <>
    <TableRow>
      <TableCell/>
      <TableCell>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
        {model}
        <AddButton to={makeUrl('/leases/create', {client_id, model_id})}/>
      </TableCell>
      <TableCell>{pluralize('bac', sumBacCnt(leases))}</TableCell>
      <TableCell/>
    </TableRow>
    <TableRow>
      <TableCell style={{borderBottom: 'unset', padding: 0}} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box ml={{xs:4, md:30}} width={{xs:0.9, md:0.5}}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Créé le</TableCell>
                  <TableCell>Fin le</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leases.map(({id, bac_count, created, end}) => (
                <TableRow key={id}>
                  <TableCell>
                    <Link to={'/leases/'+id}>
                      {end && isPast(new Date(end))
                        ? <del> {pluralize('bac', bac_count)} </del>
                        : pluralize('bac', bac_count)
                      }
                    </Link>
                  </TableCell>
                  <TableCell>{stringifyDate(new Date(created))}</TableCell>
                  <TableCell>{end && stringifyDate(new Date(end))}</TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
    </>);
};

const sumBacCnt = leases => leases
  .filter(lease => !lease.end || isFuture(new Date(lease.end)))
  .reduce((total, lease) =>  total + lease.bac_count, 0);

const stringifyDate = date => {
  const options = { day: 'numeric', month: 'short', year:'2-digit' };
  return date.toLocaleDateString('fr-FR', options);
};
