import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { Formik, Form } from 'formik';
import { Table, TableHead, TableBody, TableCell, TableRow, Box, Paper, Checkbox,
  CircularProgress } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { useCurrentUser, useUserIs } from '@clients/shared/src/user/services';
import { usePlaces, useWorkplace } from '@clients/shared/src/place/services';
import { Link, Page, AddButton, Card, makeUrl } from '../main';
import { Load } from '../_utils';
import { SearchBar, useSearch } from '../searchbar';
import UserAutocomplete from './UserAutocomplete';

/** The list of all users or visible-users in a workplace. */
export default function Users({location, history}) {
  const [ placeUsers, setPlaceUsers ] = useState([]);
  const [ settings, setSettings ] = useState([]);
  const [ destinations, setDestinations ] = useState([]);
  const [ messageTypes, setMessageTypes ] = useState([]);
  const search = qs.parse(location.search, {parseNumbers:true})
  const currentUser = useCurrentUser();
  const places = usePlaces();
  const { get, isLoading } = useApi();
  const apiSettings = useApi();
  const workplace = useWorkplace();
  const isSuper = useUserIs()('superadmin');
  const { place_id } = search;
  const isVisible = !isSuper && (workplace.id !== place_id);
  const url = `/places/${place_id}/${isVisible ? 'visible-users' : 'users'}`
  const createUrl = makeUrl(url+'/create', {workplace_id: workplace.id});
  useEffect(() => {
    place_id && get(url).then(setPlaceUsers)
  },[get, place_id, url]);

  useEffect(() => {
    get('/message-types').then(setMessageTypes);
  }, [get]);

  useEffect(() => {
    isVisible && get(
      makeUrl('/messages-settings', 
        {place_id, origin_id: workplace.id}))
      .then(setSettings)
  }, [get, workplace.id, place_id, isVisible])

  useEffect(() => {
    (workplace.id === currentUser.workplace_id
      ? (async () => places)()
      : get(makeUrl('/places', {workplace_id: workplace.id}))
    ).then(setDestinations);
  }, [workplace.id]);

  const allUsers = [ 
    ...placeUsers.map(user => ({
      ...user,
      id: user.user_id,
      visible_id: user.id,
      name: isVisible ? user.title : naming(user),
      link: isVisible ? `/visible-users/${user.id}` : `/users/${user.user_id}`,
      setting: settings.find(s => s.recipient_id===user.user_id)
    })),
    ...settings
      .map(s => ({
        id: s.recipient_id, 
        email: s.recipient_email,
        link: createUrl + '&' + qs.stringify({email: s.recipient_email}),
        setting: s
      }))
      .filter(s => placeUsers.some(u => u.email===s.email)===false)
  ]

  const filters = [
    {template: 'workplace'},
    {name: 'place_id',
      type: 'select',
      label: 'Lieu des utilisateurs',
      initialValue: place_id || workplace.id,
      clear: workplace.id,
      items: [workplace, ...destinations],
    }
  ];
  const [searchBarProps, users] = useSearch(filters, allUsers);
  useEffect(() => {
    searchBarProps.setSearchValues(v => ({...v, place_id: workplace.id}));
  }, [workplace.id]);

  const onSelectUser = (event, {user_id}) => history.push('/users/'+user_id);
  const receivedType = messageTypes.find(mt => mt.name==='received recap') || {}
  const toggleSettings = setting => {
    if (!setting.id) {
      const newSetting = {
        place_id, 
        origin_id: workplace.id, 
        message_type_id: receivedType.id,
        ...setting
      };
      return apiSettings.post('/messages-settings', newSetting)
        .then(newSetting => setSettings(settings => [...settings, newSetting]));
    } else {
      setting = {...setting, deleted: !setting.deleted};
      return apiSettings.put('/messages-settings/'+setting.id, setting)
        .then(toggled => setSettings(
          settings => settings.map(s => s.id === toggled.id ? toggled : s)))
    }
  };

  return (
    <Page title='Utilisateurs' options={<AddButton to={createUrl}/>}>
      <SearchBar {...searchBarProps}/>
      <Load isLoading={isLoading}>
        <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
          {users.length>0 ?
          <Table>
            <TableHead> 
              <TableRow>
                <TableCell>
                  Nom
                </TableCell>
                <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>
                  Email
                </TableCell>
                {isVisible && <TableCell>Mails hebdomadaires</TableCell>}
                {!isVisible && <TableCell>Role</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <UserRow
                  {...{user, isVisible, toggleSettings}}
                  key={user.id || user.email}
                />
              ))}
            </TableBody>
          </Table>
          : <Box p={4}>Aucun utilisateur trouvé</Box>}
        </Box>
      </Load>
      {isSuper && 
        <Box width={{xs:1, md:0.6, lg:0.4}} mx='auto' my={8} p={4}>
          <Card title='Rechercher un utilisateur'>
            <Formik initialValues={{id :''}} onSubmit={() => {}}>
              {() => <Form><UserAutocomplete onChange={onSelectUser}/></Form>}
            </Formik>
          </Card>
        </Box>
      }
    </Page>
  );
};

const UserRow = ({user, isVisible, toggleSettings}) => {
  const { id, email, name, role_labels: roles, link, setting } = user;
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setLoading(true);
    toggleSettings(setting || {recipient_id: id})
      .then(() => setLoading(false))
  }
  return (
    <TableRow>
      <TableCell>
        <Link to={link}>{name}</Link>
      </TableCell>
      <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>
        <Link to={link}>{email}</Link>
      </TableCell>
      {isVisible && <TableCell>
        <Checkbox  
          checked={setting!==undefined && setting.deleted===false}
          onChange={toggle}
        />
        {loading && <CircularProgress size={20}/>}
      </TableCell>}
      {!isVisible && <TableCell> {roles && roles.join(', ')} </TableCell>}
    </TableRow>)
};

const naming = ({first, last}) => (
  (first || last) ? <><strong>{last}</strong> {first}</> : ''
)
