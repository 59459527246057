import React from 'react';
import { Paper, Box, MenuItem } from '@mui/material';
import { Link, Page, H5 } from '../main';

/** A menu for leasers pages. */
export default function LeasingMenu() {
  return (
    <Page title="Gestion">
      <Box component={Paper} 
        width={{xs:0.9, md:0.5}} 
        my={{xs:4, md:16}} 
        mx='auto'
        p={2}
      >
        {links.map(link =>
        <Link key={link.uri} to={link.uri}>
          <MenuItem xs={12}>
            <H5>{link.text}</H5>
          </MenuItem>
        </Link>)}
      </Box>
    </Page>);
};

const links = [
  {uri: '/models', text: 'Modèles'},
  {uri: '/leases', text: 'Contrats'},
  {uri: '/stock-triggers', text: 'Alertes stocks'},
  {uri: '/pickups', text: 'Collectes'},
];
