import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error'
import { IconButton, Grid, Box } from '@mui/material';
import { Videocam, VideocamOff } from '@mui/icons-material';
import { analyzeScan } from '@clients/shared/src/scan';
import { SubmitButton, H6 } from '../main';
import ErrorBoundary from '../_utils/ErrorBoundary';
import Scan2D from './Scan2D';
import Scan1D from './Scan1D';
import BacInput from './BacInput';

/** Wrapper combining manual input and scanner. */
export default function Universal ({handleResult, handleResult1D}) {
  const inputRef = useRef();
  const [ showScan, setShowScan ] = useState(false);
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const handleShow = () => setShowScan(!showScan);
  const focus = React.useCallback(
    () => {inputRef.current && inputRef.current.focus()}, []);
  const beforeSubmit = setValues => setValues(
    prevValues => {
      const { bacId, input } = analyzeScan(prevValues.bac_shown_id)
      return {bac_shown_id: (bacId || input)}
    });
  const submit = async ({bac_shown_id}, {resetForm})  => (
    Promise.resolve(handleResult(bac_shown_id))
    .then(() => setSucceeded(true), () => setSucceeded(false))
    .catch(e=>{}).then(resetForm).then(focus));
  useEffect(() => {focus()}, [focus]);
  return (
    <Box m={4} minWidth='50%'>
      <Formik 
        onSubmit={submit} 
        initialValues={{bac_shown_id:''}}
        validateOnChange={false}
      >
        {({handleReset, dirty, setValues, handleSubmit}) => (
          <form onSubmit={e => {
            e.persist(); beforeSubmit(setValues).then(()=>handleSubmit(e));}}> 
          <Box minHeight={{md:150}} mb={{xs:0, md:5}}> 
          <ConnectedFocusError />
          <H6>Ajouter un bac</H6>
          <BacInput reset={() =>{handleReset(); focus()}} inputRef={inputRef}/>
          <Grid container justifyContent='space-between' alignItems='center'>
            <IconButton size="large" color='default' href='#scan' onClick={handleShow}>
              {showScan ? <VideocamOff /> : <Videocam />}
            </IconButton>
            <SubmitButton mt={0} disabled={!dirty} {...{submitSucceeded}}>
              Ajouter
            </SubmitButton>
          </Grid>
          </Box>
          </form>)}
      </Formik>
      <ErrorBoundary>
        {showScan && 
        <Video handleResult={handleResult} handleResult1D={handleResult1D}/>}
      </ErrorBoundary>
    </Box>
  );
};

const Video = ({handleResult, handleResult1D}) => {
  if (handleResult1D !== undefined) {
    return (<React.Fragment>
        <Scan1D onScan={handleResult1D}/>
        <div style={{visibility:'hidden'}}>
          <Scan2D onScan={handleResult}/>
        </div>
      </React.Fragment>)
  } else {
    return (<React.Fragment>
      <Scan2D onScan={handleResult}/>
      <div style={{height:'65px'}}></div>
    </React.Fragment>)
  }
};
