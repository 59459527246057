import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow,
  Tooltip } from '@mui/material';
import Scanner1D from './Scan1D';
import ErrorBoundary from '../_utils/ErrorBoundary';
import Button from '../main/Button';


const decodedErrorSum = (result) => {
  const decodes = result.codeResult.decodedCodes;
  let countDecodedCodes=0, err=0, error=undefined;
  for (var i=0; i<decodes.length; i++) {
    error = decodes[i].error;
    if (error!==undefined) {
      countDecodedCodes++;
      err+=parseFloat(error);
    } 
  };
  //console.log(result.codeResult.code, countDecodedCodes, err);
  return (err/countDecodedCodes);
}

const decodedErrorMax = (result) => {
  const decodes = result.codeResult.decodedCodes;
  let max=0, error=undefined;
  for (var i=0; i<decodes.length; i++) {
    error = decodes[i].error;
    if (error!==undefined && error>max) {
      max = error;
    }
  };
  return max;
}

const toPercent = (value) => {
  if (value===0) {
    return '-';
  } else if (value<0.1) {
    return (''+value).slice(3,4)+'%';
  } else if (value<1) {
    return (''+value).slice(2,4)+'%';
  }
  return value;
}


/** Test page to evaluate the 1D scanner capability. */
class TestScanner1D extends Component {
  constructor(props){
    super(props);
    this.state = {
      show_scan: true,
      results: [],
    };
  }
  handleShowScan = () => {
    this.setState(state => ({show_scan: !state.show_scan}));
    this.setState({
      results: [],
    });
  }
  handleScan = (result) => {
    console.log(result);
    this.setState(state => ({results: state.results.concat(result)}));
  }

  render(){
    const { show_scan, results, } = this.state;
    const codes = [...new Set(results.map(result => result.codeResult.code))];
    return (
      <div>
        <h1>Test code bar 1D : EAN13</h1>
        <Button
          onClick={this.handleShowScan}>
          {show_scan ? 'Stop scan' : 'Scan'}
        </Button>
        <ErrorBoundary>
        {show_scan && <Scanner1D onScan={this.handleScan}/>}
        </ErrorBoundary>
        { results.length>0 &&
        <Table padding='dense' className='superDense'> 
          <TableHead> 
            <TableRow>
              <TableCell> Code </TableCell>
              <TableCell> Count </TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'block' } }} >
                Rate
              </TableCell>
              <TableCell> Error </TableCell>
              <TableCell> Count Filt </TableCell>
              <TableCell sx={{ display: { xs: 'none', lg: 'block' } }} >
                Error Filt
              </TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
            { codes.map(code => {
              const codeResults = results.filter(r=>r.codeResult.code===code);
              const count = codeResults.length;
              const rate = toPercent(count/results.length);
              //Evaulating Error (summing decoding errors)
              const errors = codeResults.map(r=>decodedErrorSum(r));
              const errorsSum = errors.reduce((a, b) => a + b, 0);
              const errorsFilt = errors.filter(e=>e<0.1);
              const countFilt = errorsFilt.length;
              const errorsFiltSum = errorsFilt.reduce((a, b) => a + b, 0);
              //Evaulating Error 2 (take the max decoding errors)
              const errors2 = codeResults.map(r=>decodedErrorMax(r));
              const errors2Sum = errors2.reduce((a, b) => a + b, 0);
              const errors2Filt = errors2.filter(e=>e<0.2);
              const countFilt2 = errors2Filt.length;
              const errors2FiltSum = errors2Filt.reduce((a, b) => a + b, 0);
              const info = (<span> Rate: {rate} <br />
                Avg Sum Errors: {toPercent(errorsSum/count)} <br />
                Avg Max Errors: {toPercent(errors2Sum/count)} <br />
                Count Filt Sum Errors: {errorsFilt.length} <br />
                Count Filt Max Errors: {errors2Filt.length} <br />
                Avg Sum Filt Errors: {toPercent(errorsFiltSum/countFilt)}<br />
                Avg Max Filt Errors: {toPercent(errors2FiltSum/countFilt2)}<br/>
                </span>)
              return (
                <TableRow key={code}>
                  <Tooltip 
                    placement='bottom'
                    data-html='true'
                    title={info}>
                    <TableCell>{code}</TableCell>
                  </Tooltip>
                  <TableCell>{count}</TableCell>
                  <TableCell sx={{ display: { xs: 'none', lg: 'block' } }} >
                    {rate}
                  </TableCell>
                  <TableCell>{toPercent(errorsSum/count)}</TableCell>
                  <TableCell>{errorsFilt.length}</TableCell>
                  <TableCell sx={{ display: { xs: 'none', lg: 'block' } }} >
                    {toPercent(errorsFiltSum/countFilt)}
                  </TableCell>
                </TableRow>
              );})}
          </TableBody>
        </Table>
        }
      </div>
    );
  }
}

export default TestScanner1D;
