import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableBody, TableCell,  TableRow, 
  Tooltip, IconButton, } from '@mui/material';
import { Add } from '@mui/icons-material';
import qs from 'query-string';
import { useApi } from '@clients/shared/src/utils';
import Load from '../_utils/Load';
import { Page, Link, makeUrl, Sub2 } from '../main';

/** A page displaying a list of Message Settings in table. */
export default function MessagesSettings({location}) {
  const [ settings, setSettings ] = useState([]);
  const [ places, setPlaces ] = useState([]);
  const [ shownSettings, setShownSettings ] = useState([]);
  const [ messageTypes, setMessageTypes ] = useState([]);
  const apiSettings = useApi();
  const apiPlaces = useApi();
  const api = useApi();
  const { search } = location;
  const { origin_id, origin } = qs.parse(search);

  useEffect(() => { 
    apiSettings.get().then(settings => {
      setSettings(settings);
      setShownSettings(settings);
    });
    api.get('/message-types').then(setMessageTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    if (origin_id) {
      apiPlaces.get('/places?workplace_id='+origin_id).then(setPlaces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    if (apiSettings.isLoading===false && places.length>0) {
      const fakeSettings = [];
      const fakeSetting = (place, user) => ({
        origin,
        origin_id,
        place: place.name,
        place_id: place.id,
        recipient_email: user.email,
        recipient_id: user.user_id,
      });
      places.map(place => {
        if (place.employees.length===0) {
          const settingExist = settings.find(
                          ({place_id}) => place_id===place.id);
          if (settingExist) {
            return undefined;
          }
          return fakeSettings.push(fakeSetting(place, {}));
        }
        return place.employees.map(user => {
          const settingExist = settings.find(({place_id, recipient_id}) => 
              (place_id===place.id && recipient_id===user.user_id));
          if (settingExist) {
            return undefined;
          }
          return fakeSettings.push(fakeSetting(place, user));
        });
      });
      setShownSettings([...settings, ...fakeSettings]);
    }
  }, [apiSettings.isLoading, places, origin, origin_id, settings]);
  const receivedType = messageTypes.find(mt => mt.name==='received recap') || {}
  const title = 'Réglages des messages' + 
    (origin ? (' provenant de ' + origin) : '');
  const createSetting = setting => api.post('/messages-settings', setting)
    .then(newSetting => setSettings(settings => [...settings, newSetting]));
  
  return (
    <Page title={title} options={<AddMessageSettings/>}>
    <Load isLoading={apiSettings.isLoading || apiPlaces.isLoading}>
      <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
        <Table> 
          <TableHead> 
            <TableRow>
              <TableCell> Origine </TableCell>
              <TableCell> Point de livraison </TableCell>
              <TableCell> Email </TableCell>
              <TableCell> Message </TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
          {shownSettings.sort(sortByPlace).map(({id, origin_id, origin, 
              place_id, place, recipient_id, recipient_email}, index) => (
            <TableRow key={index}>
              <TableCell>
                <Link to={'/places/'+origin_id}>{origin || ''}</Link>
              </TableCell>
              <TableCell>
                <Link to={'/places/'+place_id}>{place}</Link>
              </TableCell>
              <TableCell>
                {recipient_id ?
                  <Link to={'/users/'+recipient_id}>{recipient_email}</Link> :
                  <Link to={makeUrl('/users/create', {workplace_id:place_id})}>
                    <Add />
                  </Link>}
              </TableCell>
              <TableCell>
                {id 
                  ? <Link to={'/messages-settings/'+id}>voir</Link>
                  : recipient_id && 
                    <IconButton size='small' color='inherit' onClick={
                      () => createSetting({
                        place_id, 
                        origin_id, 
                        recipient_id,
                        message_type_id: receivedType.id,
                      })}
                    >
                    <Add/>
                  </IconButton>}
              </TableCell>
            </TableRow>))}
          </TableBody>
        </Table>
        {(shownSettings.length===0) && <h3>Aucun réglage.</h3>}
      </Box>
    </Load>
    </Page>);
};

const AddMessageSettings = () => (
  <Link to='/messages-settings/create'>
    <Tooltip title={<Sub2>Ajouter</Sub2>}>
      <Add/>
    </Tooltip>
  </Link>);

const sortByPlace = (settings1, settings2) => {
  if (settings1.place > settings2.place) {
    return 1
  } else if (settings1.place < settings2.place) {
    return -1
  } else {
    return 0
  }};
