import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Grid } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { SubmitButton, DatePicker, Card, Sub2 } from '../main';

/** Form to create bulk lost events. */
export default function BulkLostForm({placeId, callback, received}) {
  const api = useApi();
  const [message, setMessage] = useState();
  const evType = received ? 'received' : 'sent';
  const evTypeFr = received ? 'récéptionnés' : 'envoyés';
  const submit = values => (
    api.post('/bulk/lost?workplace_id='+placeId, {...values, [evType]:true})
      .then(setMessage).then(callback));
  return (
    <Card mt={6}>
      <Formik onSubmit={submit} initialValues={{before: null}}>
        <Form>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Sub2>Marquer comme perdus les bacs {evTypeFr} avant le :</Sub2>
            <DatePicker name='before' label='Date' required disableFuture/>
            <SubmitButton mt={0} type='submit'>Valider</SubmitButton>
          </Grid>
        </Form>
      </Formik>
      {message && <Sub2>{message}</Sub2>}
    </Card>);
};
