import React from 'react';
import { MenuList, MenuItem } from '@mui/material';
import { CallMade } from '@mui/icons-material';
import { useWebLinks } from '@clients/shared/src/utils';
import Link from './Link';
import { H5, H6 } from './Typography';

/** Links to save one click. */
export default function QuickAccess () {
  const quickLinks = useWebLinks()
    .filter(link => ['/places', '/returns'].includes(link.to.pathname))
  return (
    <>
      <H5>Accès rapides</H5>
      <MenuList>
        {quickLinks.map(({label, to}, index) => (
        <MenuItem component={Link} key={index} to={to}>
          <H6>{label}</H6>
          <CallMade fontSize='small'/>
        </MenuItem>))}
      </MenuList>
    </>);
};
